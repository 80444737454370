
.joinOurCommunity__main-title{
    color: #00030A;
    text-align: center;
    font-family: Montserrat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem; /* 62.5% */
    /* border: 2px solid green; */
}
.JoinOurCommunity__subscription-card-container{
    /* border: 6px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.JoinOurCommunity__customer-support-container{
    /* border: 3px solid green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5em;
}

.JoinOurCommunity__associated-brands-container{
   /*  border: 2px solid blue; */
}

@media (max-width: 300px){
    .joinOurCommunity__main-title{
        padding-left: 1em;
        padding-right: 1em;
        word-break: break-word;
    }
    
}

@media (max-width: 575.98px) {

    .JoinOurCommunity__customer-support-container{
        padding-left: 1em;
        padding-right: 1em;
    }
    .JoinOurCommunity__subscription-card-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        /* border: 3px solid blue; */
        padding-left: 1.5em;
        padding-right: 1.5em;
        width: 100%;
    }
    
    .joinOurCommunity__main-title{
        padding-left: 1em;
        padding-right: 1em;
    }
    .JoinOurCommunity__main-container{
        /* border: 3px solid red; */
    }
    .JoinOurCommunity__associated-brands-container{
        padding-left: 1em;
        padding-right: 1em;
    }

}


@media (min-width: 576px) and (max-width: 767.98px) {
    .JoinOurCommunity__subscription-card-container{
        /* border: 6px solid green; */
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
}


@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}


@media (min-width: 1200px) {

}