.Policy__main-section-container{
    color: #000;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
}

.Policy__title{
    text-decoration: underline;
}
.Policy__customer-service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.Policy__main-title{
    color: #00030A;
font-family: Montserrat;
font-size: 3rem;
font-style: normal;
font-weight: 700;
line-height: 1.05rem;
margin-bottom: 1.5em;

}


@media (max-width: 575.98px) {
    .Policy__main-title{
        line-height: 3.5rem;
    }
    .Policy__main-section-container{
        padding: 2em;
    }
    .Policy__main-section-container ul li {
        word-wrap: break-word; 
    }
    .Policy__customer-service{
        padding-left: 1em;
        padding-right: 1em;
    }
    
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .Policy__main-section-container{
        padding: 2em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Policy__main-section-container{
        padding: 3em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Policy__main-section-container{
        padding: 4em;
    }
}

@media (min-width: 1200px) {
    .Policy__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .Policy__customer-service{
        width: 50%;
    }
    .Policy__main-section-container{
        padding: 2.5em;
    }
}