
.SectionInfo__main-section-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 6em;
    width: 100%;
}






/*============== CARD DESIGN ============*/
.SectionInfo__main-card-info-container{
    /* border: 10px solid rgb(45, 206, 184); */
    width: 100%;
}
    .SectionInfo__main-title-container{
        /* border: 3px solid green; */
    }
        .SectionInfo__main-title-container h1{
            /* border: 3px solid blue; */
            color: var(--primary-color);
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 3rem;
            line-height: 3.5rem;
            width: 100%;
        }

    .SectionInfo__main-body-container{
        /* border: 3px solid red; */
    }
        .SectionInfo__main-body-container p{
            /* border: 3px solid green; */
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 2.25rem;
            color: #000000;
        }
    .SectionInfo__main-button-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SectionInfo__main-button-container a{
            text-decoration: none;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5rem;
            line-height: 1.5rem;
            color: #FFFFFF ;
            font-weight: 600;
            border-radius: 0.4em;
            background-color: var(--primary-color);
            padding: .6em .3em .6em .3em;
            cursor: pointer;
        }
        .SectionInfo__inner-button-container{
            /* border: 3px solid purple; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            width: 8em;
            border-radius: 0.375em;
            text-transform: uppercase;
        }


.SectionInfo-mobile__join-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: var(--primary-color);

}
    
.SectionInfo__button{
    text-decoration: none;
}
.SectionInfo__button:hover{
    text-decoration: none;
    color: white;
}
/*==================== RESPONSIVE =========================*/


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .SectionInfo__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    } 
    .SectionInfo__main-card-info-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width:100%;
        height: 100%;
        margin: 0;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        background: none;
        /* margin-top: 3em; */
        
        
        
    }
    .SectionInfo__main-title-container{
        /* border: 2px solid blue; */
        width: auto;    
    }
        .SectionInfo__main-title-container h2 {
            color: var(--primary-color);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 1.9rem;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            padding-top:.6em;
            
            /* line-height: normal; */
            
        }
        
        .SectionInfo__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .SectionInfo__main-body-container p {
                color: #00030A;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 150% */
            }
                .SectionInfo__main-body-container p span {
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem;
                }
        .SectionInfo__main-button-container{
            /* border: 2px solid black; */
        }
            .SectionInfo__innner-button-container{
                /* border: 2px solid yellow; */
            }

            .SectionInfo__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
               
            }

            .CardInfo__important-text{
                font-size: 1.3rem !important;
                font-family: Lato;
                font-weight: 300;
            }



/*================== NEW MOBILE DESIGN ====================*/

.SectionInfo-mobile__main-section-container{
    position: relative;
   /*  border: 10px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
    .SectionInfo-mobile__img-container{
        position: relative;
        /* border: 3px solid green; */
        width: 100%;
        height: auto;
        order: 1;
        
    }
        .SectionInfo-mobile__img{
            width: 100%;
            
        }
    .SectionInfo-mobile__card-info-component-container{
        order: 2;
    }


.SectionInfo-mobile__join-title{
    margin-top: 2em;
    margin-bottom: 2em;
    word-break: break-word;

}

 }


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .SectionInfo__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       /*  width: 100%; */
    } 
    .SectionInfo__main-card-info-container{
        /* border: 10px solid rgb(0, 0, 0); */
        width:100%;
        height: 100%;
        margin: 0;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        background: none;
        /* margin-top: 3em; */
        
        
        
    }
    .SectionInfo__main-title-container{
        /* border: 2px solid blue; */
        width: auto;    
    }
        .SectionInfo__main-title-container h2 {
            color: var(--primary-color);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 1.9rem;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            padding-top:.6em;
            
            /* line-height: normal; */
            
        }
        
        .SectionInfo__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .SectionInfo__main-body-container p {
                color: #00030A;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 150% */
            }
                .SectionInfo__main-body-container p span {
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem;
                }
        .SectionInfo__main-button-container{
            /* border: 2px solid black; */
        }
            .SectionInfo__innner-button-container{
                /* border: 2px solid yellow; */
            }

            .SectionInfo__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
               
            }

            .CardInfo__important-text{
                font-size: 1.3rem !important;
                font-family: Lato;
                font-weight: 300;
            }



/*================== NEW MOBILE DESIGN ====================*/

.SectionInfo-mobile__main-section-container{
    position: relative;
    /* border: 10px solid rgb(91, 235, 2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
    .SectionInfo-mobile__img-container{
        position: relative; 
        /* border: 3px solid green; */
        width: 100%;
        height: auto;
        order: 1;
        
    }
        .SectionInfo-mobile__img{
            width: 100%;
            /* border: 10px solid orange; */
            
        }
    .SectionInfo-mobile__card-info-component-container{
        order: 2;
    }


.SectionInfo-mobile__join-title{
    margin-top: 2em;
    margin-bottom: 2em;
    word-break: break-word;

}
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionInfo__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    } 
    .SectionInfo__main-card-info-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width:100%;
        height: 100%;
        margin: 0;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        background: none;
        /* margin-top: 3em; */
        
        
        
    }
    .SectionInfo__main-title-container{
        /* border: 2px solid blue; */
        width: auto;    
    }
        .SectionInfo__main-title-container h2 {
            color: var(--primary-color);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 1.9rem;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            padding-top:.6em;
            
            /* line-height: normal; */
            
        }
        
        .SectionInfo__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .SectionInfo__main-body-container p {
                color: #00030A;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 150% */
            }
                .SectionInfo__main-body-container p span {
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem;
                }
        .SectionInfo__main-button-container{
            /* border: 2px solid black; */
        }
            .SectionInfo__innner-button-container{
                /* border: 2px solid yellow; */
            }

            .SectionInfo__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
               
            }

            .CardInfo__important-text{
                font-size: 1.3rem !important;
                font-family: Lato;
                font-weight: 300;
            }


/*================== tablet DESIGN ====================*/

.SectionInfo-tablet__main-section-container{
    position: relative;
    /* border: 10px solid rgb(91, 235, 2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
    .SectionInfo-tablet__img-container{
        position: relative; 
       /*  border: 3px solid green; */
        width: 100%;
        height: auto;
        order: 1;
        
    }
        .SectionInfo-tablet__img{
            width: 100%;
          /*   border: 10px solid orange; */
            
        }
    .SectionInfo-tablet__card-info-component-container{
        order: 2;
    }



.SectionInfo-tablet__join-title{
    margin-top: 2em;
    margin-bottom: 2em;
    word-break: break-word;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: var(--primary-color);
}

.CardInfo__important-text{
    font-size: 1.3rem !important;
    font-family: Lato;
    font-weight: 300;
}

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

    .SectionInfo__main-section-container{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
/*         border: px solid white; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 6em;
        margin-top: 6em;
    }
        .SectionInfo__main-card-info-container{
            background-color: #FFFFFF99;
            width: 100%;
            max-width: 35em;
            height: 30em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 2em;
            border-radius: 6px;
        }
        .SectionInfo__main-title-container{

            /* border: 3px solid yellow; */
            width: 100%;
        }
            .SectionInfo__main-title-container h1{
               /*  border: 3px solid green; */
                margin-bottom: 0;
            }

        .SectionInfo__main-body-container{
            /* border: 3px solid blue; */
        }

        .SectionInfo__main-button-container{
            /* border: 3px solid red; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .SectionInfo__main-body-container p {
            /* border: 3px solid green; */
            margin-bottom: 0;
            font-size: 1.3rem;
            font-weight: 300;
            
        }

    .CardInfo__important-text{

    }
    
    .CardInfo__important-text{
        font-size: 1.3rem !important;
        font-family: Lato;
        font-weight: 400;
    }
    .SectionInfo-desktop__join-title{
        margin-top: 2em;
        margin-bottom: 2em;
        word-break: break-word;
        font-family: 'Montserrat', sans-serif;
        font-size: 3rem;
        font-weight: 700;
        width: 100%;
        text-align: center;
        color: var(--primary-color);
    }

    /*================== desktop DESIGN ====================*/

.SectionInfo-desktop__main-section-container{
    position: relative;
    /* border: 10px solid rgb(91, 235, 2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
    .SectionInfo-desktop__img-container{
        position: relative; 
       /*  border: 3px solid green; */
        width: 100%;
        height: auto;
        order: 1;
        
    }
        .SectionInfo-desktop__img{
            width: 100%;
          /*   border: 10px solid orange; */
            
        }
    .SectionInfo-desktop__card-info-component-container{
        order: 2;
    }

    .SectionInfo-desktop__img-container{
        /* border: 3px solid red; */
        height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
    .SectionInfo__main-section-container{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
/*         border: px solid white; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 6em;
        margin-top: 6em;
    }
        .SectionInfo__main-card-info-container{
            background-color: #FFFFFF99;
            width: 100%;
            max-width: 35em;
            height: 30em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 2em;
            border-radius: 6px;
        }
        .SectionInfo__main-title-container{

            /* border: 3px solid yellow; */
            width: 100%;
        }
            .SectionInfo__main-title-container h1{
               /*  border: 3px solid green; */
                margin-bottom: 0;
            }

        .SectionInfo__main-body-container{
            /* border: 3px solid blue; */
        }

        .SectionInfo__main-button-container{
            /* border: 3px solid red; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .SectionInfo__main-body-container p {
           /*  border: 3px solid green; */
            margin-bottom: 0;
            font-weight: 300;
            font-size: 1.5rem;
            
        }

    .CardInfo__important-text{

    }
    
    .CardInfo__important-text{
        font-size: 1.5rem !important;
        font-family: Lato;
        font-weight: 400;
    }

    .SectionInfo-desktop__join-title{
        margin-top: 2em;
        margin-bottom: 2em;
        word-break: break-word;
        font-family: 'Montserrat', sans-serif;
        font-size: 3rem;
        font-weight: 700;
        width: 100%;
        text-align: center;
        color: var(--primary-color);
    }
    
    .SectionInfo-desktop__img-container{
        /* border: 3px solid red; */
        height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    
    
    
 }