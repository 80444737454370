.floating-button {
  position: fixed;
  bottom: 50px;
  left: 20px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  z-index: 1000; /* Asegura que el botón esté por encima de otros elementos */
}