
.Benefits__title-section-container{
    /* border: 2px solid red; */
    background-color: var(--secundary-color);
    height: auto;
}
    .Benefits__title-section-container h1{
        color: var(--primary-color);
        text-align: center;
        font-family: Montserrat;
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem;   
        height: auto;
        padding-top:1em;
        padding-bottom: 1em;
    }

@media (max-width: 575.98px) {
    .Benefits__title-section-container h1{
        font-size: 2rem;
        font-weight: 700;
    }
}
        

    
@media (min-width: 576px) and (max-width: 767.98px) {
    .Benefits__title-section-container h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .Benefits__title-section-container h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Benefits__title-section-container h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
}


@media (min-width: 1200px) {
    .Benefits__title-section-container h1{
        font-size: 3rem;
        font-weight: 700;
    }
}