
.Subscription__main-container{
    /* border: 9px solid blue; */
}
    .Subscription__title-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
        
    }
        .Subscription__title{
                /* border: 3px solid green; */
                color: #00030A;
                text-align: center;
                font-family: Montserrat;
                font-size: 2.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; /* 28% */
                margin-bottom: 0;
        }

    .Subscription__card-container{
        /* border: 8px solid rgb(0, 0, 0); */
    }
        .Subscription__card{
            /* border: 2px solid red; */
            display: flex;
            flex-direction: row;
            justify-content: center;
        }



@media (max-width: 575.98px) {
    
.Subscription__main-container{
        /* border: 9px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Subscription__card{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .Subscription__title{
         /* border: 3px solid green; */
         color: #00030A;
         text-align: center;
         font-family: Montserrat;
         font-size: 2rem;
         font-weight: 700;
         line-height: 2.5rem; 
         margin-bottom: 0;
         padding-left: 1em;
         padding-right: 1em;
    }
    .Subscription__customer-service-container{
      /*   border: 3px solid green; */
        width: 100%;
    }
        .Subscription__customer-service{
            /* border: 3px solid red; */
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
            padding-left: 1em;
            padding-right: 1em;
        } 

    .Subscription__card{
        padding-left: 1em;
        padding-right: 1em;
    }

 
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Subscription__card-container{
        /* border: 8px solid rgb(0, 0, 0); */
       
    }
    .Subscription__card{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    .Subscription__customer-service{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    } 

    .Subscription__title{
        /* border: 3px solid green; */
         color: #00030A;
         text-align: center;
         font-family: Montserrat;
         font-size: 2rem;
         font-style: normal;
         font-weight: 700;
         line-height: 2rem;
         margin-bottom: 0;
 }
    
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Subscription__main-container{
        /* border: 9px solid blue; */
    }
        .Subscription__title-container{
            /* border: 3px solid red; */
            padding-top: 2em;
            padding-bottom: 2em;
            
        }
            .Subscription__title{
                   /* border: 3px solid green; */
                    color: #00030A;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2rem; /* 28% */
                    text-transform: uppercase;
                    margin-bottom: 0;
            }
                
        .Subscription__card-container{
            /* border: 8px solid rgb(0, 0, 0); */
        }
            .Subscription__card{
                /* border: 2px solid red; */
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;
                justify-items: center;
                
            }
            .Subscription__customer-service{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 1.5em;
                margin-bottom: 1.5em;
            } 
        
    
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Subscription__customer-service{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    } 

/* .Subscription__card-container{
    border: 3px solid red;
} */
}

@media (min-width: 1200px) {
    
        .Subscription__customer-service-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
        }
        .Subscription__customer-service{
            margin-top: 2em;
            margin-bottom: 2em;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .Subscription__card-container{
           /*  border: 3px solid red; */
        }
            .Subscription__card{
                /* border: 3px solid green; */
            }
    
}