
.HotItem__carousel-container{

    padding: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    
}
    .HotItem__carousel{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

    }
        .HotItem__prev-btn-container, .HotItem__next-btn-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }   

            .HotItem__prev-btn, .HotItem__next-btn{
                cursor: pointer;
                font-size: 24px;
                background: rgb(5, 64, 136, 1);
                color: white;
                /* border: 2px solid rgba(5, 64, 136, 1); */
                outline: none;
                padding: .6em !important;
                border-radius: .3em .3em .3em .3em;
                transition: color 0.3s ease-in-out;
                border: none;
        
            }

.HotItem__main-title{
    color: #00030A;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    padding: .5em 0em .5em 0em;
}

/*============= PREV AND NEXT BTNS EFFECTS ===========*/
.HotItem__prev-btn:hover{
    background-color: rgb(25, 78, 142);
    
}
.HotItem__next-btn:hover{
    background-color: rgb(25, 78, 142);
}






/*==================== RESPONSIVE ============*/




/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .HotItem__main-container{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 2em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
    }
    .HotItem__carousel-container{
        /* border: 2px solid blue; */
        padding: 0;
    }
    .HotItem__prev-btn, .HotItem__next-btn{
        cursor: pointer;
        padding: .3em;
        border-radius: .3em .3em .3em .3em;
        transition: color 0.3s ease-in-out;
    }

    .HotItem__main-title{
        font-size: 3rem;
        line-height: 1rem;
        font-weight: 700;

    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    /* .HotItem__main-container{
        border: 3px solid blue;
        
    }
    .HotItem__carousel-main{
        border: 3px solid red;
        display: flex;
        flex-direction: row;
    } */

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

 }