
.BecomeMember__title{
    color: #00030A;
    font-family: Montserrat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.05rem;
    text-align: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}

.BecomeMember__payment-form-container{
    /* border: 10px solid red; */
    margin-bottom: 2em;
}



@media (max-width: 575.98px) {
    .BecomeMember__main-section{
        /* border: 9px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .BecomeMember__main-title-container{
        /* border: 6px solid red; */
    }
        .BecomeMember__title{
            /* border: 3px solid yellow; */
            font-size:3rem;
            line-height: 2.7rem;

        }
        .BecomeMember__payment-form-container{
           /*  border: 6px solid red; */
        }
 
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .BecomeMember__title{
        /* border: 3px solid yellow; */
        font-size:3rem;
        line-height: 3rem;
    }
    .BecomeMember__payment-form-container{
        /* border: 3px solid blue; */
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
}