.CustomerService__information-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 0.625rem;
    padding: 1em;
    width: 100%;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* border: 3px solid red; */
}
    .CustomerService__contact-information{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
        .CustomerService__service-number-contact-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
        }
            .CustomerService__person-support-icon-container{
                /* border: 2px solid green; */
                margin-right: 1em;
            }
            .CustomerService__service-number-container{
              /* border: 3px solid black; */
            }
            .CustomerService__service-number-container p{
                color: #C4364A;
                font-family: Montserrat;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem; 
                letter-spacing: -0.09219rem;
            
            }

        .CustomerService__email-contact-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
        }
            .CustomerService__email-icon-container{
                /* border: 2px solid green; */
                margin-right: 1em;
            }
            .CustomerService__email-container{
                /* border: 3px solid black; */
                
            }
                .CustomerService__email-container p{
                    color: #C4364A;
                    font-family: Montserrat;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; 
                    letter-spacing: -0.09219rem;
                }

    .CustomerService__description-information{
        /* border: 2px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 2.5em;
    }
        .CustomerService__description-information p {
            color: #00030A;
            font-family: Lato;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.75rem;   
        }



/*============== RESPONSIVE ===========*/

@media (max-width: 325px){
    .CustomerService__service-number-contact-container, .CustomerService__email-contact-container{
        display: flex !important;
        flex-direction:  column !important;
        align-items: center !important;
    }
  
}
@media (max-width: 435px){

    .CustomerService__service-number-container p{
        font-size: 1rem !important;
    }
    .CustomerService__email-container p{
        font-size: 1rem !important;
    }
}


@media (max-width: 575.98px) { 
   .CustomerService__information-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 25em;
   }
    .CustomerService__contact-information{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border: 3px solid red; */
        width: 100%;
    }
        .CustomerService__service-number-contact-container{
            /* border: 3px solid blue; */
            display: flex;
            width: 100%;
            flex-direction: row;
            
            
        }

            .CustomerService__person-support-icon-container{
                /* border: 8px solid green; */
                width: 100%;
                width: 3em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }
                .CustomerService__person-support-icon-container img{
                    width: 100%;

                }
            .CustomerService__service-number-container{
                /* border: 3px solid blue; */

            }
                .CustomerService__service-number-container p{
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: 1.5rem;
                   /*  border: 3px solid red; */
                    margin-bottom: 0;
                }




            .CustomerService__email-contact-container{
              /*   border: 3px solid green; */
                width: 100%;
                display: flex;
                flex-direction: row;
            }
                .CustomerService__email-icon-container{
                 /*    border: 3px solid blue; */
                    width: 100%;
                    width: 3em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                    .CustomerService__email-icon-container img{
                        width: 100%;
                    }

            .CustomerService__email-container{
             /*    border: 3px solid blue; */
            }
                .CustomerService__email-container p{
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: 1.5rem;
                    /* border: 3px solid red; */
                    margin-bottom: 0;
                }






    
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .CustomerService__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        width: 70%;
        flex-direction: column;
    }
        .CustomerService__contact-information{
            /* border: 2px solid red; */
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           
        }
            .CustomerService__service-number-contact-container{
                /* border: 3px solid green; */
                width: 100%;    
              
            }
                .CustomerService__person-support-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .CustomerService__service-number-container{
                  /* border: 3px solid black; */
                }
                .CustomerService__service-number-container p{
                    font-size: 1rem;
                }
    
            .CustomerService__email-contact-container{
                /* border: 3px solid green; */
                width: 100%;
            }
                .CustomerService__email-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .CustomerService__email-container{
                    /* border: 3px solid black; */
                    
                }
                    .CustomerService__email-container p{
                        font-size: 1rem;
                   
                    }
    
        .CustomerService__description-information{
            /* border: 2px solid green; */
            margin: 0;
        
        }
            .CustomerService__description-information p {
                /* border: 2px solid red; */
                text-align: center;
            }
 }


@media (min-width: 768px) and (max-width: 991.98px) {
    .CustomerService__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        width: 70%;
        flex-direction: row;
    }
        .CustomerService__contact-information{
            /* border: 2px solid red; */
            width: auto;
           
        }
            .CustomerService__service-number-contact-container{
                /* border: 3px solid green; */
              
            }
                .CustomerService__person-support-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .CustomerService__service-number-container{
                  /* border: 3px solid black; */
                }
                .CustomerService__service-number-container p{
                    font-size: 1rem;
                }
    
            .CustomerService__email-contact-container{
                /* border: 3px solid green; */
               
            }
                .CustomerService__email-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .CustomerService__email-container{
                    /* border: 3px solid black; */
                    
                }
                    .CustomerService__email-container p{
                        font-size: 1rem;
                   
                    }
    
        .CustomerService__description-information{
            /* border: 2px solid green; */
            margin: 0;
        
        }
            .CustomerService__description-information p {
                /* border: 2px solid red; */
                text-align: center;
            }
 }


@media (min-width: 992px) and (max-width: 1199.98px) { 
    .CustomerService__information-container{    
        width:35em;
        flex-direction: column;
    }
    .CustomerService__description-information p {
        text-align: justify;
        margin-bottom: 0;
    }
 }


@media (min-width: 1200px) { 
    .CustomerService__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        width:50em;
        flex-direction: row;
        margin-bottom: 2em;
    }
    
    .CustomerService__description-information p {
        text-align: justify;
        margin-bottom: 0;
    }
 }